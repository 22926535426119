.tile-border{border:1px solid var(--varNeutral500);border-radius:8px}[data-tracker] > *{pointer-events:none;cursor:pointer}[data-tracker-pointer-events] > *{pointer-events:all;cursor:pointer}*{font-variant-ligatures:none}
/* stylelint-disable font-family-no-missing-generic-family-keyword */
html {
  font-family: var(--tkww-union-typography-primary-font-family, union-primary-font, union-sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif);
  font-weight: var(--tkww-union-typography-regular-font-weight, 400);
}
h1 {
  font-weight: var(--tkww-union-typography-category-h1-font-weight, var(--tkww-union-typography-bold-font-weight, 500));
  font-family: var(--tkww-union-typography-category-h1-font-family, var(--tkww-union-typography-primary-font-family, union-primary-font, union-sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif));
  font-size: var(--tkww-union-typography-category-h1-font-size, 1.875rem);
  line-height: var(--tkww-union-typography-category-h1-line-height, 1.2);
}

h2 {
  font-weight: var(--tkww-union-typography-category-h2-font-weight, var(--tkww-union-typography-bold-font-weight, 500));
  font-family: var(--tkww-union-typography-category-h2-font-family, var(--tkww-union-typography-primary-font-family, union-primary-font, union-sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif));
  font-size: var(--tkww-union-typography-category-h2-font-size, 1.75rem);
  line-height: var(--tkww-union-typography-category-h2-line-height, 1.2142857143);
}

h3 {
  letter-spacing: var(--tkww-union-typography-category-h3-letter-spacing, 0);
  font-weight: var(--tkww-union-typography-category-h3-font-weight, var(--tkww-union-typography-bold-font-weight, 500));
  font-family: var(--tkww-union-typography-category-h3-font-family, var(--tkww-union-typography-primary-font-family, union-primary-font, union-sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif));
  font-size: var(--tkww-union-typography-category-h3-font-size, var(--tkww-union-typography-scale-600-font-size, 1.5rem));
  line-height: var(--tkww-union-typography-category-h3-line-height, 1.1666666667);
}

h4 {
  letter-spacing: var(--tkww-union-typography-category-h4-letter-spacing, 0);
  font-weight: var(--tkww-union-typography-category-h4-font-weight, var(--tkww-union-typography-bold-font-weight, 500));
  font-family: var(--tkww-union-typography-category-h4-font-family, var(--tkww-union-typography-primary-font-family, union-primary-font, union-sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif));
  font-size: var(--tkww-union-typography-category-h4-font-size, 1.375rem);
  line-height: var(--tkww-union-typography-category-h4-line-height, 1.1818181818);
}

@media (max-width: 767.98px) {
  h1 {
    font-size: var(--tkww-union-typography-category-h1-font-size, 1.75rem);
    line-height: var(--tkww-union-typography-category-h1-line-height, 1.2142857143);
  }
}
@media (max-width: 767.98px) {
  h2 {
    font-size: var(--tkww-union-typography-category-h2-font-size, 1.625rem);
    line-height: var(--tkww-union-typography-category-h2-line-height, 1.2307692308);
  }
}
@media (max-width: 767.98px) {
  h3 {
    font-size: var(--tkww-union-typography-category-h3-font-size, 1.5rem);
    line-height: var(--tkww-union-typography-category-h3-line-height, 1.1666666667);
  }
}
@media (max-width: 767.98px) {
  h4 {
    font-size: var(--tkww-union-typography-category-h4-font-size, 1.375rem);
    line-height: var(--tkww-union-typography-category-h4-line-height, 1.1818181818);
  }
}
p {
  font-size: var(--tkww-union-typography-scale-300-font-size, 1rem);
  line-height: var(--tkww-union-typography-scale-300-line-height, 1.5);
}
strong {
  font-weight: 500;
}

em {
  font-style: italic;
}

a {
  /* stylelint-disable font-family-no-missing-generic-family-keyword */
  font-family: var(--tkww-union-typography-primary-font-family, union-primary-font, union-sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif);
  font-weight: inherit;
}

p {
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}

html {
  /**
   * fontSize300 with hardcoded font-size
   */
  /* stylelint-disable unit-case */
  font-size: var(--tkww-union-typography-base-font-size, 16px);
  line-height: var(--tkww-union-typography-base-line-height, 1.5);
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}




a {
  color: var(--tkww-union-links-default-color, var(--tkww-union-color-link-on-light, var(--tkww-union-color-additional-01extra-400, #0073E6)));
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}

:focus {
  outline: none;
}

:focus-visible {
  outline-color: var(--tkww-union-essentials-outline-color, var(--tkww-union-color-additional-01extra-400, #0073E6));
  outline-offset: var(--tkww-union-essentials-outline-offset, 2px);
  outline-style: solid;
  outline-width: 3px;
}

html {
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
ol,
ul {
  margin: 0;
  padding: 0;
}

img,
picture {
  display: block;
  height: auto;
  max-width: 100%;
  width: auto;
}

a,
area,
button,
[role=button],
input,
label,
select,
summary,
textarea {
  touch-action: manipulation;
}

input,
textarea,
button,
select {
  color: inherit;
  font-family: inherit;
}

button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0;
}
.mask--1d999{color:#666;background:rgba(255,255,255,.7);width:100%;height:100%;display:flex;align-items:center;justify-content:center;flex-direction:column;position:fixed;top:0;left:0;z-index:20}.wrapper--7317a{font-size:14px;position:relative}.wrapperBefore--c44f4{position:absolute;display:block;top:50%;left:50%;transform:translate(-50%, -50%);width:36px;height:36px;content:"";background-repeat:no-repeat;background-position:center}@keyframes spin--749ff{0%{transform:rotate(0deg)}100%{transform:rotate(360deg)}}.loader--07231{font-size:inherit;border-left:.25em solid #19b5bc;border-top:.25em solid rgba(25,181,188,.2);border-right:.25em solid rgba(25,181,188,.2);border-bottom:.25em solid rgba(25,181,188,.2);transform:translateZ(0);animation:spin--749ff 1.1s infinite linear;border-radius:50%;padding:2em}
.overlay--4ac2d{display:flex;justify-content:center;align-items:center}.overlaySpinner--d59de{width:4em;height:4em}.overlaySpinner--d59de>div{border-left-color:#2b72bf}.overlaySpinner--d59de::before{content:"";background:url("https://media-api.xogrp.com/images/fa7fcce7-0f67-4db0-9753-af902ab75920");width:70%;height:70%;position:absolute;top:9px;left:18px;background-repeat:no-repeat}
.animated--de112 {
  transition: ease-in 300ms;
  transition-property: background-color, visibility;
}

.overlay--f7774 {
  height: 100%;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  top: 0;
  visibility: var(--union-modal-visibility, visible);
  width: 100%;
  z-index: var(--union-modal-z-index, 20);
}
.overlay--f7774.not-visible--73d04, .overlay--f7774.not-enabled--81fe6 {
  --overlay-opacity: 0;
}
.overlay--f7774.not-enabled--81fe6 {
  pointer-events: none;
}

.color-dark--9a58c {
  background-color: rgba(31, 31, 31, var(--overlay-opacity, 0.7));
}

.color-light--74535 {
  background-color: rgba(255, 255, 255, var(--overlay-opacity, 0.8));
}

.fixed-to-relative--20c20 {
  position: absolute;
}

.fixed-to-page--bc509 {
  position: fixed;
}
.size-default--0f675 {
  height: 2.5em;
  width: 2.5em;
}
.size-default--0f675 .spinner--99554.spinner--99554 {
  stroke-width: 0.78px;
}

.size-sm--67c12 {
  height: 1em;
  width: 1em;
}
.size-sm--67c12 .spinner--99554.spinner--99554 {
  stroke-width: 1.95px;
}

.size-md--e4cb3 {
  height: 1.5em;
  width: 1.5em;
}
.size-md--e4cb3 .spinner--99554.spinner--99554 {
  stroke-width: 1.3px;
}

.size-lg--7ac1a {
  height: 2em;
  width: 2em;
}
.size-lg--7ac1a .spinner--99554.spinner--99554 {
  stroke-width: 0.98px;
}

.loading--fadb3 {
  animation: rotate-animation--1db20 1.6s infinite linear;
  position: relative;
}

@keyframes rotate-animation--1db20 {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
.loading--fadb3 .spinner--99554 {
  animation-direction: reverse;
  animation-duration: 1.6s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  animation-name: loading--animation--9f17c;
  animation-timing-function: ease-in-out;
  fill: none;
  stroke: var(--tkww-union-spinner-color, var(--tkww-union-color-additional-01extra-400, #0073E6));
  stroke-dasharray: 64.056;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  transform-origin: 50% 50%;
}

@keyframes loading--animation--9f17c {
  0%, 25% {
    stroke-dashoffset: 16;
    transform: rotate(270deg);
  }
  50%, 75% {
    stroke-dashoffset: 58;
    transform: rotate(315deg);
  }
  100% {
    stroke-dashoffset: 16;
    transform: rotate(-90deg);
  }
}
